import { Atom, Ctrl, Mod } from ":mods";
import { BASE_ROUTES } from "../const";
import { Route } from "@solidjs/router";
import { actions, hooks } from "../store";
import { NotFoundWithRedirect } from "../components";
import { Dashboard as DashboardLayout, dashboard_actions } from "../../layouts";
import { TutorViewProps } from "../model";

export function Tutor() {
  const $: TutorViewProps["$"] = {
    actions: {
      ...actions,
      ...dashboard_actions,
      ...Mod.Account.actions,
      ...Mod.Courses.actions,
    },
    hooks: {
      ...hooks,
    },
    CONST: {
      ACCOUNT: {
        TOKEN_TYPE: Mod.Account.CONST.TOKEN_TYPE,
      },
    },
  };

  return (
    <Atom.Route.Guarded
      base={BASE_ROUTES.tutor}
      errorElement={() => <NotFoundWithRedirect path={"/"} base={BASE_ROUTES.tutor} duration={3000} />}
      layout={() => (
        <DashboardLayout
          topNav={{
            logoHref: BASE_ROUTES.tutor,
            base: BASE_ROUTES.tutor,
            items: [
              Ctrl.Nav.Element.Filler,
              Mod.I18n.Nav.TopSelector,
              Ctrl.Nav.Element.Divider,
              Mod.Account.Nav.TopNotifications,
              Ctrl.Nav.Element.Divider,
              Mod.Account.Nav.TopProfile,
            ],
          }}
          sideNav={{
            class: "nav-side",
            base: BASE_ROUTES.tutor,
            items: [Mod.Schedule.CONST.ANCHORES.side.student.foundation, Mod.Courses.Nav.SideTutorCourse],
          }}
        />
      )}
      guard={{
        title: "museum training tutor",
        steps: [
          Mod.Account.guard.checkUserToken,
          Mod.Account.guard.checkUserEvents,
          // Mod.Courses.guard.checkSelectedStage,
        ],
      }}
      events={{
        onGuardSuccess: (trigger, { state }) => {
          const token_type = $.actions.getTokenType();
          const courses = $.actions.getEventsDetails().courses || {};
          if (token_type !== Mod.Account.CONST.TOKEN_TYPE.tutor) {
            trigger.navigate({ base: BASE_ROUTES.student });
          } else if (!courses["1"]?.is_application_submitted) {
            trigger.navigate({ path: Mod.Signup.CONST.ROUTES.SIGN_UP_TUTORS, base: BASE_ROUTES.signup });
          } else {
            trigger.loadRoute();
          }
        },
        onGuardFailure: (trigger, { stepEquals, error }) => {
          console.log("from tutor failed firstLoad");
          const courses = $.actions.getEventsDetails()?.courses || {};
          if (stepEquals(Mod.Account.guard.checkUserToken)) {
            // console.log("failed to load local once");
            trigger.navigate({ base: BASE_ROUTES.auth });
          } else if (!courses["1"]?.is_application_submitted) {
            trigger.navigate({ path: Mod.Signup.CONST.ROUTES.SIGN_UP_TUTORS, base: BASE_ROUTES.signup });
          } else if (stepEquals(Mod.Courses.guard.checkSelectedStage)) {
            trigger.navigate({ base: BASE_ROUTES.tutor });
          } else {
            // maybe you'll need trigger.loadRoute(), i'll leave this to you
          }
        },
        onRouteCleanup({}) {
          $.actions.clearLayer();
        },
      }}
    >
      {() => (
        <>
          <Mod.Account.ProfileRoutes $={$} />
          <Mod.Schedule.TutorRoutes $={$} />
          <Mod.Courses.TutorRoutes $={$} />
          <Route path={"/"} element={<div>welcome to tutor dashboard</div>} />
        </>
      )}
    </Atom.Route.Guarded>
  );
}
